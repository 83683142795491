.mainbody{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

.headline_comp{
  text-align: center;
  font-size: 2.5rem;
  margin-top: 0.5rem;
}

.bold{
  font-weight: bold;
}

/* besser zentrieren */
.seasonheadline{
  font-size: 1.5rem;
  font-weight: bold;    
  vertical-align: middle;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin-left: 5vw;
}  

.frame_compname{
  font-size: 1rem;
  font-weight: bold;    
  vertical-align: middle;
  display: inline-block;
  vertical-align: middle;
  line-height: normal; 

  /* font-size: 1.25rem;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  vertical-align: auto;
  text-align: center; */
}  

.compname{
  font-size: 1.25rem;
  font-weight: bold;
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  vertical-align: auto;
  text-align: center;
}

.frame_fontsize{
  font-size: 1.5rem;
}

.outer_frame{
  margin-top: 0.5rem;
  width: 100vw;
  justify-items: center;
  display: grid;    
}

.frame{
  background-color: whitesmoke;
  border-radius: 15px;
  width: 90vw;
  height: 100px; 
  line-height: 100px; 
  margin-bottom: 5px;    
  column-count: 3;
}

.frame_col{
  width: 30vw;
  max-width: 30vw;
}

.special_frame{
  background-color: whitesmoke;
  border-radius: 15px;
  width: 90vw;
  height: 50px; 
  line-height: 50px; 
  margin-bottom: 5px;    
  text-align: center;
}

.frameseason{
  background-color: whitesmoke;
  border-radius: 15px;
  width: 90vw;
  height: 100px; 
  line-height: 100px; 
  margin-bottom: 5px;    
  column-count: 8;
}

.complogo{
  text-align: left;
  max-width: 30vw;
  max-height: 80px;
  min-height: 80px;
  width: auto;
  height: auto;
  margin-left: 20px;
  margin-top: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.tablelogo{
  text-align: center;
  margin-left: 2px;
  height: 70px;
}

.tablelogofirstpage{
  text-align: center;
  margin-left: 2px;
  height: 19px;
}

/*
Tablelogo 
18 Vereine: 0.cm = 11.61
24.75px
20 Vereine: 0.5cm = 10
19px = 380px
*/


.compflag{    
  height: 60px;
  margin-right: 10px;
  right: 0;
  vertical-align: middle;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  border-radius: 5px;
}

.tablefont{
  font-weight: bold;
  font-size: 1rem;
}

.tablefontfirstpage{
  font-weight: bold;
  font-size: 0.8rem;
}

@media only screen and (max-width: 1024px) {
  .complogo{
    margin-left: 10px;
  }
} 