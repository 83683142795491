h1, h2, h3{
    text-align: center;
}

h1{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

h2{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.container_features{
    position: relative;
    text-align: center;
    border-radius: 15px;
    border-color: var(--mainColor);
    border-width: 5px;
    width: 96vw;
    background-color: white;
    border-style: solid;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 2vw;
    margin-right: 2vw;
}

.features{
    font-size: 1.4rem;
    list-style-image: url("https://www.sorare-album.com/assets/fb_bulletin.svg");
}

.features_individual{
    margin-bottom: 20px;
    margin-left: 3vw;
    margin-right: 1vw;
}

ul{
    display: inline-block;
    text-align: left;
}

.mainpic{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60vw;
}


.nomatch_text{
    text-align: center;
    font-size: 1.5rem;
}

.centerpic{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.block_colored{
    background-color: var(--mainColor);
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-flow: wrap;
}


.box{
    width: 22%;
    height: auto;
    background-color: white;
    margin: 20px;
    border-radius: 10px;
    box-sizing: border-box;
}

@media screen and (max-width:1200px) {
    .box{
        width: 40%;
    }    

    .features_individual{
        margin-left: 4vw;
    }

    .features{
        margin-left: 4vw;
    }
}

@media screen and (max-width:600px) {
    .box{
        width: 90%;
    }    

    .mainpic{
        width: 90vw;
    }

    .features_individual{
        margin-left: 7vw;
    }

    .features{
        font-size: 1.1rem;
        margin-left: 7vw;
    }
}
