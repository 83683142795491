:root{
    --mainColor: #29335c;
    /* helleres Blau: #004aad; */
    /* dunkleres Blau: #29335c; */
    --mainColorLight:#5767aa;
    --secondaryColor: #DB2B39;
    --textColor: #eee;
    --PictureUrl: "https://sorare-album.com/assets"
}

.content-box {  
    width: width;
    height: 231;
    border: 2px solid yellow;
    background: white;
}

.divlogo{
  display: flex;
  justify-content: center;
}

.flag{    
  display: flex;
  justify-content: center;
}

.main_button {
	/* background:linear-gradient(to bottom, #ffffff 5%, #ffffff 100%); */
	background-color:#ffffff;
	border-radius: 10px;
	border:1px solid #1e8fff;
	display:inline-block;
	cursor:pointer;
	color:#1e8fff;
	font-family:Arial;
	font-size: 1.5rem;
	font-weight:bold;
	padding:6px 20px;
	text-decoration:none;
	text-shadow:0px 1px 0px #1e8fff;
  margin-top:10px;
}
.main_button:hover {
	background-color:#1e8fff;
  color: #ffffff;
}
.main_button:active {
	position:relative;
	top:1px;
}

        

/* .headline{
  text-align: center;
  font-size: 2.5rem;
  margin-top: 1rem;
}   */

.smallheadline{
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.main{
  margin-top: 110px;
}