.essent-div{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    margin-left: 1vw;
    margin-right: 1vw;
}

.essent-div-data{
    display: flex;
    flex-direction: column;
    width: 98vw;
    margin-left: 1vw;
    margin-right: 1vw;
    font-size: 1.1rem;
    font-weight: bold;
}

.support_text{
  font-size: 1.1rem;
  font-weight: bold;
  margin-left: 1vw;
  margin-right: 5vw;
}

.supportbutton {
  position: relative;
  font-weight: bold;
  font-size: 1rem;
  border-color: blue;
  background-color: azure;
  color: blue;
  border-radius: 15px; 
  padding-left: 10px;
  padding-right: 10px;
}

.supportbutton:hover {
  color: azure;
  background-color: blue;
}

.headline_essential {
    margin-top: 1vh;
    font-size: 3rem;
    text-align: center;
}

.cookie_headline{
  font-size: 1.5rem;
  text-align: left;
  margin-top: 20px;
}

.cookie_headline_sm{
  font-size: 1.1rem;
  text-align: left;
}

.cookie_bulletin{
  font-size: 1rem;
}

.smallheadline{
    font-size: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: left;
    margin-left: 1vw;
}

.content{
  margin-bottom: 0.25rem;
  margin-left: 1.5vw;
}

p{
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.walletpic{
  display: block;
  margin-left: 2vw;
  width: 100px;
}

.bmacpic{
  display: block;
  margin-left: 2vw;
  height: 50px;
}

h3, h4{
  margin-bottom: 0.4rem;
  margin-top: 0.5rem;
}

h5{
  margin-bottom: 0.25rem;
}

ul{
  margin-bottom: 0.5rem;
}

li{
  margin-bottom: 0.25rem;
  margin-left: 2vw;
}

.verificationDiv{
  width: 40vw;
  display: block;
	margin-left: auto;
	margin-right: auto;
}

@media only screen and (max-width: 1024px) {
  .verificationDiv{
    width: 80vw;
  }

  .essent-div-data{    
    width: 94vw;
    margin-left: 4vw;
    margin-right: 2vw;
    hyphens: auto;
  }

  .cookie_bulletin{
    margin-left: 6vw;
  }
}