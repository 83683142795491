.switch{
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    right: 0;
}

.switch input{
    opacity: 0;
    width: 0;
    height: 0;
}

.slider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    background-color: #ccc;
    border-radius: 34px;
}

.slider::before{
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider{
    background-color: #2196f3;
}

input:checked + .slider::before{
    transform: translate(26px);
}