.outer_box_pdf{
    position: relative;
    /*Für das Kreieren muss die Margin weg, um den schwarzen Block links zu entfernen*/
    width: 21cm;
    height: 29.7cm;
    border-style: solid;
    border-color: var(--mainColor);
    text-align: center;
    align-items: center;
    justify-items: center;
    background-image: url(https://sorare-album.com/assets/Rasen.png);     
    /*visibility: hidden;*/
}

.outer_cover_pdf{
    position: relative;
    margin: auto;
    width: 21cm;
    height: 29.7cm;
    border-style: solid;
    border-width: 5px;
    border-color: var(--mainColor);
    text-align: center;
    align-items: center;
    justify-items: center;    
    margin-bottom: 10px;
    background-image: url(https://sorare-album.com/assets/Rasen.png);     
}

.pagenumber_pdf{
    position: absolute;
    font-weight: bold;
    font-size: 1rem;  
    bottom: -0.25rem;
    left: calc(21cm / 2 - 1.5rem);
    width: 3rem;
    background-color: aliceblue;
    border-radius: 5px;
}

.imgplayer_pdf { 
    justify-content: center;    
    border-radius: 0.6rem;
    height: 150px;
    /* check if player images are in the background on ios */
    z-index: 1000;
}

.imgfblogo_pdf {
    justify-content: center;    
    border-radius: 0.6rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    max-height: 150px;
    max-width: 150px;
}

.userlogo_pdf {
    justify-content: center;
    height: 80px;
    margin-top: 2px;
}

.imgflag_pdf {
    justify-content: center;
    height: 20px;
    margin-top: 2px;
    z-index: 1000;
}

.inner_card_pdf{
    width: 203.86px;
    height: 330px;
    margin-top: 33.13px;
    margin-left: 45.5275px;
    border-style: solid;
    border-width: 5px;
    border-color: darkgray;
    border-radius: 0.6rem;
    background-color: lightgray;
    text-align: center;
    align-items: center;
    justify-items: center;
    float: left;    
}

.inner_limited_pdf{
    width: 203.86px;
    height: 330px; 
    margin-top: 33.13px;
    margin-left: 44.76375px;
    text-align: center;
    align-items: center;
    justify-items: center;
    float: left;
    position: relative;
}

.imgcard_pdf{
    height: 330px;
}

.clubdata_pdf{
    height: 75px;  
    border-radius: 10px;
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 15px;
}

.coverBackground_pdf{
    height: 425px;
    background-image: url(https://sorare-album.com/assets/Rasen.png);    
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.featured_card_box_pdf{
    width: calc((783.69px / 2));
    height: 380px; 
    margin-top: 33.13px;
    text-align: center;
    align-items: center;
    justify-items: center;
    display: inline-block;
}

.featured_card_pdf{
    width: 203.86px;
    height: 330px; 
    margin-top: -33.13px;
    margin-left: 50px;
    margin-bottom: 50px;
}

.featured_owned_card_pdf{
    width: 203.86px;
    height: 330px; 
    margin-left: 44.76375px;
    margin-top: 33.13px;
}

.featured_text_pdf{
    width: 203.86px;
    background-color: white;
    border-radius: 15px;
    font-size: 1.5rem;
    font-weight: bold;    
    margin: 10px auto;
}

.manager_data_pdf{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 300px;
}

.featured_text_manager_pdf{   
    width: fit-content; 
    background-color: white;
    border-radius: 10px;
    font-size: 1.5rem;
    font-weight: bold;    
    margin: 10px auto;
    padding: 0 0.5rem;
}

.nameheadline_pdf{
    text-align: center;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 0.5em;
}

.desc_pdf{
    font-size: 0.25rm;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.smheadline_pdf{
    font-size: 0.25rm;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    font-weight: bold;
}

.centered{
    justify-content: center;
    text-align: center;
    min-height: 100vh;
}

.power{
    position: absolute;
    font-weight: bold;
    font-size: 1rem;
    bottom: 0.6rem;
    right: 0.7rem;
    background-color: beige;
    width: 3.5rem;
    border-radius: 0.25rem;
}

.powersilver{
    position: absolute;
    font-weight: bold;
    font-size: 1rem;
    bottom: 0.6rem;
    right: 0.7rem;
    background-color: silver;
    width: 3.2rem;
    border-radius: 0.25rem;
}

.powergold{
    position: absolute;
    font-weight: bold;
    font-size: 1rem;
    bottom: 0.6rem;
    right: 0.7rem;
    background-color: gold;
    width: 3.2rem;
    border-radius: 0.25rem;
}

.paginationButton {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
}

.paginationButton a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid var(--mainColor);
    color: var(--mainColor);
    cursor: pointer;
}

.paginationBotton a:hover {
    color: white;
    background-color: var(--mainColor);
}

.paginationActive a {
    color: white;
    background-color: #2b2eff;
}

.paginationDisabled a {
    color: grey;
    background-color: grey;
}

.albumButtonFrame{
    text-align: center;
    margin-bottom: 5px;
}

.albumButton{
    text-align: center;
}

.fontcover_pdf{
    text-align: center;    
    font-weight: bold;
    font-size: 5rem;
    margin-top: 10px;
}

.compLogo_pdf{
    height: 200px;
}

.clubsBackground_pdf{
    height: 405px;
    margin-top: 10px;
}

.coverClubs_pdf{
    width: 90px;
    margin-top: 15px;
    margin-left: 25px;
    text-align: center;
    align-items: center;
    justify-items: center;
    float: center;  
    position: relative;
}

.featured_points{
    width: 90px;
    background-color: white;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: bold;   
    
    text-align: center;
    align-items: center;
    justify-items: center;
    float: center;  
    position: relative;
}

/*Format für Coverseite mit Karten darüber*/
/* .coverClubs{
    width: 90px;
    margin-top: 15px;
    margin-left: 10px;
    text-align: center;
    align-items: center;
    justify-items: center;
    float: center;  
    position: relative;
} */

.loading_animation{
    width: 25vw;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 1024px) {

    .loading_animation{
        width: 70vw;
    }
}