:root{
    --wi: calc((100vw - (5*203.86px))/10);
}

.header_comps{
    width: 100%;    
    background-color: dodgerblue;
}

.header_americansports{
    width: 100%;    
}

.loading_spinner{
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.button_album{
    color: white;
    border-color: white;
    border-width: 5px;
    background-color: dodgerblue;    
    font-size: 26px;
    font-weight: bold;
    padding: 5px;
}

.clubname{
    color: white;
    font-size: 26px;
    font-weight: bold;
    text-align: left;
    margin-top: 10px;
}

.left_col{
    margin-left: 50px;
}

.compname_seasons{
    color: white;
    font-size: 26px;
    font-weight: bold;
    text-align: left;
    margin-top: 10px;
}

.club_info{
    color: white;
    font-size: 16px;
    text-align: left;
}

.col_width{
    width: calc(100vw / 3);
    height: 110px;
}

/* .comp_div{
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
} */

.header_clublogo{
    height: 90px;
    background-color: white;
    border-radius: 10px;
}

.club_logo{
    background-color: white;
    border-radius: 15px;
    position: absolute;
    height: 90px;
    margin-left: 5vw;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.flag_logo{
    border-radius: 15px;
    position: absolute;
    height: 90px;
    margin-top: 10px; 
    margin-left: 50px;  
}

.compname_seasons{
    vertical-align: auto;
    text-align: center;
    position: absolute;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 50px;
    margin-top: 30px;
}

.row
{
    display: flex;
    justify-content: center;
    align-items: center;
}
.row > *
{
    display: table-cell;
    height: 110px; 
}

@media screen and (max-width: 500px) {
    .row
    {
        display: block;
        float: left;
    }
    .row > *
    {
        display: block;
    }

    .col_width{
        width:100vw;
        margin-bottom: 5px;
    }

    .clubname, .club_info{
        margin-left: 20vw;
    }
    
    .comp_div{
        height: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
    }

    .compname_seasons{
        position: static;
    }

    .club_logo{
        background-color: white;
        border-radius: 15px;
        position: absolute;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }

    .button_album{
        position: absolute;
        color: white;
        border-color: white;
        border-width: 5px;
        background-color: dodgerblue;    
        font-size: 26px;
        font-weight: bold;
        left: 20vw;
        right: 20vw;
    }

    .flag_logo{
        border-radius: 15px;
        position: absolute;
        height: 90px;
        margin-left: calc(50% - 135px / 2);
        margin-top: 10px;        
    }
    
}

.imgplayerclub { 
    justify-content: center;    
    border-radius: 0.6rem;
    margin-bottom: 0.2rem;
    height: 150px;
}

.imgnba {
    justify-content: center;    
    border-radius: 0.6rem;
    margin-bottom: 0.5rem;
}

/* .imgfblogo {
    justify-content: center;    
    border-radius: 0.6rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
} */

.imgflagclub {
    justify-content: center;
    margin-top: 0.3rem;
    height: 20px;
}

@media only screen and (min-width: 250px){
    .outer_cards{
        --cards: 1;
    }
} 
@media only screen and (min-width: 425px){
    .outer_cards{
        --cards: 2;
    }
}
@media only screen and (min-width: 675px){
    .outer_cards{
        --cards: 3;
    }
}
@media only screen and (min-width: 850px){
    .outer_cards{
        --cards: 4;
    }
}
@media only screen and (min-width: 1050px){
    .outer_cards{
        --cards: 5;
    }
}
@media only screen and (min-width: 1300px){
    .outer_cards{
        --cards: 6;
    }
}
@media only screen and (min-width: 1550px){
    .outer_cards{
        --cards: 7;
    }
} 

.outer_cards{
    margin-top: 30px;
}

.card_box{
    width: 203.86px;
    height: 380px;
    margin-left: calc((100vw - (var(--cards) * 203.86px)) / (var(--cards) + 1));
    text-align: center;
    align-items: center;
    justify-items: center;
    float: left; 
    position: relative;   
}

.inner_blanco{
    width: 203.86px;
    height: 330px;
    border-style: solid;
    border-width: 5px;
    border-color: darkgray;
    border-radius: 0.6rem;
    background-color: lightgray;
    text-align: center;
    align-items: center;
    justify-items: center;
    position: relative;
}

.inner_owned{
    width: 203.86px;
    height: 330px; 
    text-align: center;
    align-items: center;
    justify-items: center;
    float: left;
    position: relative;
}

.img_owned{
    height: 330px;
}

.headline_club{
    text-align: center;
    font-size: 2.5rem;
/*     font-weight: bold;*/    
    margin-top: 0rem;
    margin-bottom: 0rem;
}

.headline_album_club{
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
}

.smheadline_club{
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 0.5rem;
    margin-bottom: -0.5rem;
}

.nameheadline_club{
    text-align: center;
    font-size: 100%;
    font-weight: bold;
    margin-top: 0.55rem;
    margin-bottom: 0.5rem;
}

.desc_club{
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

.power{
    position: absolute;
    font-weight: bold;
    font-size: 1rem;
    bottom: 0.6rem;
    right: 0.7rem;
    background-color: beige;
    width: 3.5rem;
    border-radius: 0.25rem;
}

.powersilver{
    position: absolute;
    font-weight: bold;
    font-size: 1rem;
    bottom: 0.6rem;
    right: 0.7rem;
    background-color: silver;
    width: 3.2rem;
    border-radius: 0.25rem;
}

.powergold{
    position: absolute;
    font-weight: bold;
    font-size: 1rem;
    bottom: 0.6rem;
    right: 0.7rem;
    background-color: gold;
    width: 3.2rem;
    border-radius: 0.25rem;
}

.findbutton {
    position: relative;
    font-weight: bold;
    font-size: 1rem;
    border-color: blue;
    background-color: azure;
    color: blue;
    border-radius: 15px; 
    width: 195px;
    margin-bottom: 50px;
    margin-top: 5px;
    left: 0
}

.findbutton:hover {
    color: azure;
    background-color: blue;
}

.findbutton:last-child{
    margin-bottom: 70px;
}