.outer_box{
    position: relative;
    /*Für das Kreieren muss die Margin weg, um den schwarzen Block links zu entfernen, der beim Druck auftritt*/
    margin: auto;
    width: 21cm;
    height: 29.7cm;
    border-style: solid;
    border-width: 5px;
    border-color: var(--mainColor);
    text-align: center;
    align-items: center;
    justify-items: center;
    background-image: url(https://sorare-album.com/assets/Rasen.png);    
    margin-bottom: 10px;
}

.offscreen{
    position: absolute;
    left: 100vw;
    overflow:auto;
}

.outer_box_pdf{
    position: relative;
    /*Für das Kreieren muss die Margin weg, um den schwarzen Block links zu entfernen*/
    width: 21cm;
    height: 29.7cm;
    border-style: solid;
    border-color: var(--mainColor);
    text-align: center;
    align-items: center;
    justify-items: center;
    background-image: url(https://sorare-album.com/assets/Rasen.png);     
    /*visibility: hidden;*/
}

.outer_cover{
    position: relative;
    margin: auto;
    width: 21cm;
    height: 29.7cm;
    border-style: solid;
    border-width: 5px;
    border-color: var(--mainColor);
    text-align: center;
    align-items: center;
    justify-items: center;    
    margin-bottom: 10px;
    background-image: url(https://sorare-album.com/assets/Rasen.png);     
}

.info_alert{
    width: 40vw;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.imgplayer { 
    justify-content: center;    
    border-radius: 0.6rem;
    height: 150px;
}

.imgfblogo {
    justify-content: center;    
    border-radius: 0.6rem;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    max-height: 170px;
    max-width: 150px;
}

.userlogo {
    justify-content: center;
    height: 80px;
    margin-top: 2px;
}

.imgflag {
    justify-content: center;
    height: 20px;
    margin-top: 2px;
}

.inner_card{
    width: 203.86px;
    height: 330px;
    margin-top: 33.13px;
    margin-left: 45.5275px;
    border-style: solid;
    border-width: 5px;
    border-color: darkgray;
    border-radius: 0.6rem;
    background-color: lightgray;
    text-align: center;
    align-items: center;
    justify-items: center;
    float: left;    
}

.inner_limited{
    width: 203.86px;
    height: 330px; 
    margin-top: 33.13px;
    margin-left: 44.76375px;
    text-align: center;
    align-items: center;
    justify-items: center;
    float: left;
    position: relative;
}

.imgcard{
    height: 330px;
}

.coverBackground{
    height: 425px;
    background-image: url(https://sorare-album.com/assets/Rasen.png);    
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.featured_card_box{
    width: calc((783.69px / 2));
    height: 380px; 
    margin-top: 33.13px;
    text-align: center;
    align-items: center;
    justify-items: center;
    display: inline-block;
}

.featured_card{
    width: 203.86px;
    height: 330px; 
    text-align: center;
    align-items: center;
    justify-items: center;
}

.featured_text{
    width: 203.86px;
    background-color: white;
    border-radius: 15px;
    font-size: 1.5rem;
    font-weight: bold;    
    margin: 10px auto;
}

.manager_data{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    width: 300px;
}

.featured_text_manager{   
    width: fit-content; 
    background-color: white;
    border-radius: 10px;
    font-size: 1.5rem;
    font-weight: bold;    
    margin: 10px auto;
    padding: 0 0.5rem;
}

.nameheadline{
    text-align: center;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.desc{
    font-size: 0.25rm;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

.desc_club{
    font-size: 0.25rm;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    font-weight: bold;
}

.centered{
    justify-content: center;
    text-align: center;
    min-height: 100vh;
}

.power{
    position: absolute;
    font-weight: bold;
    font-size: 1rem;
    bottom: 0.6rem;
    right: 0.7rem;
    background-color: beige;
    width: 3.5rem;
    border-radius: 0.25rem;
}

.powersilver{
    position: absolute;
    font-weight: bold;
    font-size: 1rem;
    bottom: 0.6rem;
    right: 0.7rem;
    background-color: silver;
    width: 3.2rem;
    border-radius: 0.25rem;
}

.powergold{
    position: absolute;
    font-weight: bold;
    font-size: 1rem;
    bottom: 0.6rem;
    right: 0.7rem;
    background-color: gold;
    width: 3.2rem;
    border-radius: 0.25rem;
}

.pagenumber{
    position: absolute;
    font-weight: bold;
    font-size: 1rem;  
    bottom: -0.4rem;
    left: calc(21cm / 2 - 1.5rem);
    width: 3rem;
    background-color: aliceblue;
    border-radius: 5px;
}

.albumButtonFrame{
    text-align: center;
    margin-bottom: 5px;
}

.albumButton{
    text-align: center;
}

.fontcover{
    text-align: center;    
    font-weight: bold;
    font-size: 5rem;
    margin-top: 10px;
}

.compLogo{
    height: 200px;
}

.clubsBackground{
    height: 405px;
    margin-top: 10px;
}

.coverClubs{
    width: 90px;
    max-height: 110px;
    margin-top: 15px;
    margin-left: 25px;
    text-align: center;
    align-items: center;
    justify-items: center;
    float: center;  
    position: relative;
}

.featured_points{
    width: 90px;
    background-color: white;
    border-radius: 15px;
    font-size: 1rem;
    font-weight: bold;   
    
    text-align: center;
    align-items: center;
    justify-items: center;
    float: center;  
    position: relative;
}

/*Format für Coverseite mit Karten darüber*/
/* .coverClubs{
    width: 90px;
    margin-top: 15px;
    margin-left: 10px;
    text-align: center;
    align-items: center;
    justify-items: center;
    float: center;  
    position: relative;
} */


@media only screen and (max-width: 1024px) {
    .outer_box{
        position: relative;
        margin: auto;

        --wid: 100vw;
        --hei: calc(var(--wid)/0.707070707070707);

        width: var(--wid);
        height: var(--hei);

        border-style: solid;
        border-width: 5px;
        border-color: var(--mainColor);
        text-align: center;
        align-items: center;
        justify-items: center;
        background-image: url(https://sorare-album.com/assets/Rasen.png);    
        margin-bottom: 50px;
    }

    .outer_cover{
        position: relative;
        margin: auto;

        --wid: 100vw;
        --hei: calc(var(--wid)/0.707070707070707);

        width: var(--wid);
        height: var(--hei);
        border-style: solid;
        border-width: 5px;
        border-color: var(--mainColor);
        text-align: center;
        align-items: center;
        justify-items: center;    
        margin-bottom: 10px;
        background-image: url(https://sorare-album.com/assets/Rasen.png);     
    }

    .pagenumber{
        position: absolute;
        font-weight: bold;
        font-size: 0.5rem;  
        bottom: -0.4rem;
        left: calc(100vw / 2 - 1.5rem);
        width: 3rem;
        background-color: aliceblue;
    }

    .inner_card{
        display: block;
        --card-wid: calc(var(--wid)*0.256847675444122);
        width: var(--card-wid);
        height: calc(var(--card-wid)*1.61875797115668);
        /* changed margin-top from 0.0417412120448532 to 0.0317412120448532 */
        margin-top: calc(var(--wid)*0.0317412120448532);
        margin-left: calc(var(--wid)*0.057361093612196);
        border-style: solid;
        border-width: 5px;
        border-color: darkgray;
        border-radius: 0.6rem;
        background-color: lightgray;
        text-align: center;
        align-items: center;
        justify-items: center;
        float: left;            
    }

    .inner_limited{
        --card-wid: calc(var(--wid)*0.256847675444122);
        width: var(--card-wid);;
        height: calc(var(--card-wid)*1.61875797115668);
        /* changed margin-top from 0.0417412120448532 to 0.0317412120448532 */
        margin-top: calc(var(--wid)*0.0317412120448532);
        margin-left: calc(var(--wid)*0.057361093612196);
        float: left;
    }

    .imgcard{
        --card-wid: calc(var(--wid)*0.256847675444122);
        width: var(--card-wid);
        --card-height: calc(var(--card-wid)*1.61875797115668);
        height: var(--card-height);
    }

    .imgplayer { 
        justify-content: center;    
        border-radius: 0.6rem;
        height: calc(var(--wid)*0.157490235605392);
        z-index: 1000;
        margin-bottom: 0.1rem;
    }
        
    .nameheadline{
        text-align: center;
        font-size: 2.2vw;
        font-weight: bold;
        margin-top: 4px;
        margin-bottom: 4px;
    }
    
    .desc{
        /* font-size: 0.55rem; */
        font-size: 2.2vw;
        margin-top: 0.055vh;
        margin-bottom: 0.055vh;       
    }
 
    .imgflag {
        justify-content: center;
        height: calc(var(--wid)*0.025);
        position: relative;
        /* bottom: 0px; */
        --height: calc(var(--card-wid)*1.61875797115668);
        margin-top: 1vh;
    }

    .imgfblogo{
        justify-content: center;    
        border-radius: 0.6rem;     
        max-width: calc(var(--card-wid)*0.65);        
        /*max-height: calc(var(--card-height)*0.5); */
        height: calc(var(--card-height)*0.4);
    }

    .coverBackground{
        height: 425px;
        background-image: url(https://sorare-album.com/assets/Rasen.png);    
        border-radius: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    .featured_card_box{
        width: calc((783.69px / 2));
        height: 380px; 
        margin-top: 33.13px;
        text-align: center;
        align-items: center;
        justify-items: center;
        display: inline-block;
    }
    
    .featured_card{
        width: 203.86px;
        height: 330px; 
        text-align: center;
        align-items: center;
        justify-items: center;
    }
    
    .featured_text{
        width: 203px;
        background-color: white;
        border-radius: 15px;
        font-size: 1rem;
        font-weight: bold;    
        margin: 10px auto;
    }
    
    .manager_data{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        width: 300px;
    }
    
    .featured_text_manager{   
        width: fit-content; 
        background-color: white;
        border-radius: 10px;
        font-size: 1.5rem;
        font-weight: bold;    
        margin: 10px auto;
        padding: 0 0.5rem;
    }

    .fontcover{
        text-align: center;    
        font-weight: bold;
        font-size: 2rem;
        margin-top: 10px;
    }
    
    .compLogo{
        height: 100px;
    }
    
    .clubsBackground{
        height: calc(var(--hei) / 3);
        width: calc(var(--wid) * 0.9);
        margin-left: auto;
        margin-right: auto;
    }

    .coverClubs{
        width: calc(var(--wid) * 0.11);
        margin-top: calc(var(--wid) * 0.02);
        margin-left: calc(var(--wid) * 0.025);
        text-align: center;
        align-items: center;
        justify-items: center;
        float: center;  
        position: relative;
    }

    .info_alert{
        width: 95vw;
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

}

.paginationDiv{
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    margin-bottom: 10px;
}

.custom-item {
    background-color: #fff;
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #29335c;
    color: #fff;
    margin-left: -0.1rem;
    margin-right: -0.1rem;
}

.custom-item--active {
    background-color: dodgerblue;
    border-radius: 50px;
    color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-item--disable {
    opacity: 0.5;
}

.custom-root ul {
    display: flex;
    list-style: none;
    padding: 0;     
}

.custom-go-item {
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    width: 50px;
    margin-left: -0.1rem;
    margin-right: -0.2rem;
}

.custom-go-item input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 40px;
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    padding-inline-start: 0.5rem;
}