@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;    
}

header {
    display: flex;
	align-items: center;
    justify-content: space-between;
    /* Make a fixed navbar (problem: overrides other stuff 
    position: fixed;
    top: 0; */	
    height: 80px;
    padding: 0 2rem;
    background-color: var(--mainColor);
    color: var(--textColor);
    font-weight: bold;     
    z-index: 1100;
    overflow: hidden;
}

nav a{
    margin: 0 2rem;
    color: var(--textColor);
    text-decoration: none;    
}

nav a:hover{
    color: var(--secondaryColor);
}

header .nav-btn {
	padding: 0px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

.logo{
	height: 60px;
	border-radius: 10px;
	margin-left: -20px;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	header nav {
		position: absolute;
        padding-top: 25px;
		top: 0;
		left: 0;
		height: 100%;
		width: 100vw;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transform: translateY(-100vh);          
        z-index: 1100;
	}

	header .responsive_nav {	
		position: fixed;
		transform: none; 	
	}

	nav .nav-close-btn {
		position: absolute;
		top: 20px;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;		
		margin-bottom: 10px;	
	}

	nav a:first-child {
		margin-top: 25vw;	
	}
}