.contact_form {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 100vw;
    margin: auto;
}

.contact_text{
    font-size: 17px;
}

.contact_label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.contact_input,
.contact_textarea {
    font-size: 1rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
    margin-bottom: 1rem;
}

.contact_button {
    background-color: #0077b6;
    color: #fff;
    font-size: 1.2rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.contact_button:hover {
    background-color: #023e8a;
}

.contact_input:focus,
.contact_textarea:focus {
    outline: none;
    border-color: #0077b6;
    box-shadow: 0 0 0 2px rgba(0, 119, 182, 0.2);
}
