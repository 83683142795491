.footer{
    background-color: var(--mainColor);
    color: var(--textColor);
    width: 100%;
    padding-block: 1.5rem;
    position: relative;
    display: flex;
}

.footer_box{
    width: 80%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1.3fr repeat(2, 1fr);
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;
}

.footer_mid{
    background-color: var(--mainColor);
    color: var(--textColor);
    padding-block: 0.75rem;
}

.footer_below{
    background-color: var(--mainColor);
    color: var(--textColor);
    padding-block: 0.75rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer_mid_title{
    text-align: left;
    margin-bottom: 0.5rem;
    margin-left: 0.75rem;
    font-size: 18px;
}

.footer_mid_text{
    text-align: left;
    margin-left: 0.75rem;
    font-size: 14px;
}

.footer_title{
    text-align: left;
    margin-bottom: 0.75rem;
}

.footer_text{
    margin-left: 0.5rem;
    text-decoration: inherit;
    color: white;
}

.foot-link{
    color: var(--textColor);
}

.copyright{
    text-align: left;
    margin-left: 0.75rem;
}

.privacy{
    text-align: left;
    margin-right: 0.75rem;
}

@media screen and (max-width: 45em){
    .footer_box{
        width: 90%;
        grid-template-columns: 1fr 2fr;
    }
}

@media screen and (max-width: 35em){
    .footer_box{
        width: 90%;
        grid-template-columns: 1fr;
    }

    .footer_below{
        flex-direction: column;
        justify-content: center;
    }

    .copyright{
        text-align: center;
    }
    
    .privacy{
        text-align: center;
    }    
}