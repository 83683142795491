.item {
    display: inline-block;
}

.title_about{
  font-weight: bold;
  font-size: 18px;
  margin-left: 1vw;
}


.text{
  font-size: 17px;
  margin-left: 1vw;
  margin-right: 1vw;
}




/* on PC */
@media screen and (min-width: 550px) {
  .featured-image {
    float: left;
    width: 40%;
    padding-right: 15px;
  }
  .featured-image img {
    width: 100%;
  }
  .description {
    float: left;
    width: 60%;
    hyphens: auto;
  }
}
/* on Mobile */
@media screen and (max-width: 550px) {
  .featured-image {
    width: 100%;
  }
  .featured-image img {
    width: 100%;
  }
  .description {
    width: 100%;
    hyphens: auto;
  }
}