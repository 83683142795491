.register {
  background-color: white;
  justify-content: center;
  position: absolute;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 100vh;
}

.changepw {
  background-color: white;
  justify-content: center;
  position: absolute;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 20vh;
}

section {
  width: 100%;
  max-width: 420px;
  height: 100%;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  background-color: rgba(2, 80, 159, 0.9);
  color: white;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 1rem;
}

.account_container{
  display: flex;
  margin-bottom: 12px;
}

.side {
  flex: 1; 
}

input[type="text"],
input[type="password"],
button,
textarea {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

label{  
  margin-top: 1rem;
}

.buttonreg {
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: white;
  color: #1e8fff;
  border-color: #1e8fff;
}

.buttonreg:disabled{
  background-color: darkgrey;
  color: #000;
  border-color: black;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  border-radius: 5px;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.line {
  display: inline-block;
}

.link{
  color: white;
}

.leftdesc{
  text-align: left;
  font-size: 1.8rem;
  margin-bottom: 0.75rem;
}

.rightdesc{
  text-align: right;
  font-size: 1.8rem;
  margin-bottom: 0.75rem;
}

.logininfo{
  text-align: left;
  font-size: 1.25rem;
}

.rightfield{
  position: absolute;
  right: 10px;
}

.headline{
  text-align: center;
  font-size: 2.5rem;
/*   font-weight: bold; */
  margin-bottom: 0.5rem;
} 

.deletion{
  margin-top: 5px;
  border-color: red;
  background-color: lightcoral;
  color: black;
}

.positive{
  border-color: #1e8fff;
  background-color: white;
  color: black;
}

.bold_button{
  font-weight: bold;
}

.centered_button{
  padding: 5px 10px; 
  display: block;
	margin: auto;
}

.edit{
  margin-top: 5px;
  border-color: black;
  background-color: lightblue;
  right: 0.5rem;
  top: 0.75rem;
  position: absolute;
}

.rounded{
  border-radius: 5px;
}

.inputs{
  width: 250px;
}

@media only screen and (max-width: 1024px) {
  .leftdesc{
    font-size: 1.4rem;
  }

  .rightdesc{
    font-size: 1.4rem;
  }

  .inputs{
    width: 40vw;
    font-size: 1rem;
  }

}